<template>
  <div class="page-home page-login">
    <page-header h1='Request Access'></page-header>

    <div class="container">
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-9 center form-group">
            <label class="center" for="phone-number-input">Enter your phone number:</label>
            <div v-if="error" class="validation-message text-danger">Invalid phone number</div>
            <phone-number-input v-model="phone" @focus="error = false" class="form-control center" id="phone-number-input" required></phone-number-input>
          </div>
        </div>
        <div class="row">
          <div class="col-9 center form-group">
            <button type="submit" class="btn btn-primary center">
              <div class="spinner-border spinner-border-sm" v-if="saving"></div>
              <span>Submit</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import PhoneNumberInput from './PhoneNumberInput';

  export default {
  name: 'Login',
  components: {
    PageHeader,
    PhoneNumberInput
  },
  data() {
    return {
      phone: "",
      error: null,
      url: process.env.VUE_APP_API_URL,
      saving: false,
    }
  },
    created() {
      let storedPhone = window.localStorage.getItem('driver_phone_number')
      if(storedPhone && storedPhone !== "null") {
        this.phone = storedPhone
      }
    },
  methods: {
    submit() {
      window.localStorage.setItem('driver_phone_number', null)

      this.saving = true
      window.axios.post( this.url + 'get-code', {
        phone: this.phone.replace(/-/g, '')
      }).then(() => {
        window.localStorage.setItem('driver_phone_number', this.phone)
        this.$emit('has-code')
        this.saving = false
      }).catch(e => {
        console.log(e)
        this.error = true
        this.saving = false
      })
    }
  }
}
</script>
