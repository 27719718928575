<template>
  <div id="app">


    <div class="modal" tabindex="-1" role="dialog"  v-if="showInstall">
      <div class="modal-dialog w-50 mx-auto" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Install App to Desktop</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img src="/img/icons/truck.png" width=80 alt="">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="install">Install</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal"  @click="dismiss()">Dismiss</button>
          </div>
        </div>
      </div>
    </div>



    <nav class="nav navbar navbar-expand-lg navbar-dark bg-dark show">
      <a class="navbar-brand text-light" href="#" @click.prevent="handleNavClick('/')">Pickup Issue App</a>
      <button class="navbar-toggler" type="button" @click="$store.commit('TOGGLE_SHOW_MENU')">
        <span class="navbar-toggler-icon"></span>
        <span class="sr-only">Menu</span>
      </button>

      <div class="collapse navbar-collapse" :class="{show: $store.state.showMenu}">
        <ul class="navbar-nav mr-auto">

          <li class="nav-item" v-if="$store.state.token">
            <a class="nav-link text-light" href="#" @click.prevent="handleNavClick('action')">Report Pickup Issue</a>
          </li>

          <li class="nav-item active">
            <a class="nav-link text-light" href="#" @click.prevent="handleNavClick('about')">About App</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-light" href="#" @click.prevent="handleNavClick('install')">App Installation Instructions</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-light" href="#" @click.prevent="refresh">Refresh Page</a>
          </li>
          <li class="nav-item" v-if="$store.state.token">
            <a class="nav-link text-light" href="#" @click.prevent="logout">Logout</a>
          </li>
            <li class="nav-item" v-else>
                <a class="nav-link text-light" href="#" @click.prevent="handleNavClick('home')">Request Access</a>
            </li>
        </ul>
      </div>
    </nav>

    <main>
      <router-view/>
    </main>
  </div>
</template>

<script>
  export default {
    name: "App",
    data() {
      return {
        deferredPrompt: true,
        showMenu: false,
        showInstall: false,
      };
    },
    created() {
      window.addEventListener("beforeinstallprompt", e => {
        console.log('beforeinstallprompt');
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        this.showInstall = true;
      });
      window.addEventListener("appinstalled", () => {
        this.deferredPrompt = null;
        this.showInstall = false;
      });
    },
    methods: {
      async dismiss() {
        this.deferredPrompt = null;
        this.showInstall = false;
      },
      async install() {
        this.deferredPrompt.prompt();
        this.showInstall = false;
      },
      logout() {
        this.$store.commit('CLEAR_TOKEN')
        this.handleNavClick({ name: 'Home'})
      },
      handleNavClick(route) {
        this.$store.commit('SET_SHOW_MENU', false)

        if (this.$route.path !== route){
          this.$router.push(route)
        }
      },
      refresh() {

          var url = location.origin;
          var pathname = location.pathname;
          var hash = location.hash;

          location = url + pathname + '?application_refresh=' + (Math.random() * 100000) + hash;

      }
    }
  };
</script>
