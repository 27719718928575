import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/main.css';
import axios from 'axios';

window.axios = require("axios");

Vue.component('file-select', '@/components/FileSelect');
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    axios.interceptors.response.use(
        response => response,
        error => {
          if (error.response && error.response.status === 401) {
            this.$store.commit('CLEAR_TOKEN')
            this.$router.push('/')
          }
          return Promise.reject(error)
        }
    )
  }
}).$mount('#app')
