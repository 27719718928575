<template>
  <input type="tel" v-model="number" @keypress="isNumberKey" @input="handleInput">
</template>

<script>
export default {
name: "PhoneNumberInput",
  props: {
    value: {
      default: ''
    }
  },
  data() {
    return {
      number: this.value
    }
  },
  methods: {
    isNumberKey(evt)
    {
      var charCode = (evt.which) ? evt.which : event.keyCode;

      if (charCode != 46 && charCode != 45 && charCode > 31
          && (charCode < 48 || charCode > 57))
        return evt.preventDefault();

      return true;
    },
    handleInput() {
      this.number = this.addDashes(this.number)
      this.$emit('input', this.number)
    },
    addDashes(input) {
      var r = /(\D+)/g,
          npa = '',
          nxx = '',
          last4 = '';
      // strip all non numeric characters
      input = input.replace(r, '');

      // divide into sections
      npa = input.substr(0, 3);
      nxx = input.substr(3, 3);
      last4 = input.substr(6, 4);

      return [npa, nxx, last4].filter(v => !!v).join('-')
    }

  }
}
</script>

<style scoped>

</style>