/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
      let version = process.env.PACKAGE_VERSION || '0'
      console.log("latest version: " + version)

      if(sessionStorage.getItem('_trash_app_version') != version) {
        console.log("Version is outdated reloading")
        sessionStorage.setItem('_trash_app_version', version)
        var url = location.origin;
        var pathname = location.pathname;
        var hash = location.hash;

        location = url + pathname + '?application_refresh=' + (Math.random() * 100000) + hash;
      }

    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
