<template>
  <div class="page-home page-code">
    <page-header h1='Enter Access Code'></page-header>

    <div class="container">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-9 center form-group">
            <label class="center" for="code">Enter the access code that was texted to you:</label>
            <div v-if="error" class="validation-message text-danger">Invalid code</div>
            <input type="text" id="code" class="form-control center" autocomplete="off" v-model="code" @focus="error = false" required>
          </div>
        </div>
        <div class="row">
          <div class="col-9 center form-group">
            <button type="submit" class="btn btn-primary center">
              <div class="spinner-border spinner-border-sm" v-if="saving"></div>
              <span>Submit</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-9 center">
            <a href="#" @click.prevent="$emit('request-code')">Request new access code</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
export default {
  name: 'Code',
  components: {
    PageHeader,
  },
  data() {
    return {
      code: null,
      error: false,
      url: process.env.VUE_APP_API_URL,
      saving: false,
    }
  },
  methods: {
    handleSubmit() {
      this.error = false
      this.saving = true
      window.axios.post(this.url + 'verify-code', {
        code: this.code,
        grant_type: "code"
      }).then(res => {
        this.$store.commit('SET_TOKEN', res.data.token)
        this.$router.push('action')
        this.saving = false
      }).catch(e => {
        console.log(e)
        // alert(e)
        this.error = true
        this.saving = false
      })

    }
  }

}
</script>
