<template>
  <div class="page-action">
    <page-header h1='Report Pickup Issue'></page-header>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="mb-4">Select why the pickup could not be completed:</p>
        </div>
      </div>
    </div>

    <div class="action-buttons">
      <div class="action-button-wrapper" v-for="issue in issues" :key="issue">
        <button class="btn btn-primary" @click="handleIssue(issue)">{{issue}}</button>
      </div>
    </div>
    
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";

export default {
  name: 'ActionSelect',
  components: {
    PageHeader,
  },
  data() {
    return {
      issues: [
        'Blocked', 'Overflowing', 'Locked', 'Other'
      ],
    }
  },
  mounted() {
    this.$store.commit('SET_SHOW_MENU', false)
  },
   methods: {
    handleIssue(issue) {
      this.$store.commit('SET_ISSUE', issue)
      this.$router.push({path: 'add-issue', params: {issue: issue}})
    },

  }
}
</script>
