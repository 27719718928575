<template>
  <div class="page-about">
    <page-header h1='About App' back='/'></page-header>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="mt-5">{{ $options.name }}</h2>
          Version {{ version }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";

export default {
    name: "About",
    components: {
      PageHeader,
    },
    data() {
        return {
            version: process.env.PACKAGE_VERSION
        }
    },
}
</script>
