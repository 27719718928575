import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ActionSelect from '../views/ActionSelect.vue'
import AddIssue from '../views/AddIssue.vue'
import {TokenService} from '../services/TokenService';
import Success from "../views/Success";
import InstallationInstructions from "../views/InstallationInstructions";
import About from "../views/About";
import LocationError from "../views/LocationError";

Vue.use(VueRouter)

const routes = [
    {
        path: '/index.html',
        alias: '/',
        name: 'Home',
        component: Home,
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    {
        path: '/action',
        name: 'Action',
        component: ActionSelect
    },
    {
        path: '/add-issue',
        name: 'AddIssue',
        component: AddIssue
    },
    {
        path: '/success',
        name: 'Success',
        component: Success
    },
    {
        path: '/location-error',
        name: 'LocationError',
        component: LocationError,
    },
    {
        path: '/install',
        name: 'InstallationInstructions',
        component: InstallationInstructions,
        meta: {
            public: true,  // Allow access to even if not logged in
        }
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            public: true,  // Allow access to even if not logged in
        }
    },

    {
        path: '*',
        component: Home,
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    }

]


const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public)
    const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
    const loggedIn = !!TokenService.getToken();

    if (!isPublic && !loggedIn) {
        return next({
            name: 'Home',
            query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
        });
    }

    // Do not allow user to visit login page or register page if they are logged in
    if (loggedIn && onlyWhenLoggedOut) {
        return next({path: '/action'})
    }

    next();
})

/// silences NavigationDuplicated from being redirect to '/'
function patchRouterMethod (router, methodName)
{
    router['old' + methodName] = router[methodName]
    router[methodName] = async function (location)
    {
        return router['old' + methodName](location).catch((error) =>
        {
            if (error.name === 'NavigationDuplicated')
            {
                return this.currentRoute
            }
            throw error
        })
    }
}

patchRouterMethod(router, 'push')
patchRouterMethod(router, 'replace')


export default router
