<template>  
  <div>
    
    <login @has-code="hasCode = true" v-if="!hasCode"></login>
    <Code @request-code="hasCode = false" v-if="hasCode"></Code>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'
import Code from '@/components/Code.vue'

export default {
  name: 'Home',
  components: {
    Login, Code
  },
  data() {
    return {
      hasCode: false,
    }
  },
  methods: {
    
  }
}
</script>
