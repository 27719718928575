<template>
  <div class="page-installation-instructions">
    <page-header h1='App Installation Instructions' back='/'></page-header>

    <div class="container">
      <div class="row">
          <div class="col-12">

              <h2 class="mb-2">Select Device Type:</h2>
              <ul class="mb-4 ml-0 pl-0" style="list-style-type:none;">
                <li>
                  <span v-if="os === 'iOS'">iOS</span>
                  <a v-else href="#" @click.prevent="setOS('iOS')">iOS</a>
                </li>
                <li>
                  <span v-if="os === 'Android'">Android</span>
                  <a v-else href="#" @click.prevent="setOS('Android')">Android</a></li>
                <li>
                  <span v-if="os !== 'iOS' && os !== 'Android'">Other</span>
                  <a v-else href="#" @click.prevent="setOS(null)">Other</a>
                </li>
              </ul>

              <div v-if="os === 'iOS'">
                <h2 id="ios">Apple iOS</h2>

                <p>From any of the screens you can install a icon on your phone desktop.</p>

                  <ol class="text-left ml-0">
                      <li class="mb-3">
                        At the bottom of the screen, click
                        <img src="/assets/iOS-Install-Icon.png" width="20" alt="iOS install icon">
                        <div class="mt-2 text-center">
                          <a href="/assets/osx-01.png"><img src="/assets/osx-01.png"
                               alt="At the bottom of the screen, click iOS install icon"></a>
                        </div>
                      </li>
                      <li class="mb-3">
                        Scroll down and then click "Add to Home Screen"
                        <div class="mt-2 text-center">
                          <a href="/assets/osx-02.png"><img src="/assets/osx-02.png"
                               alt="Scroll down and then click Add to Home Screen"></a>
                        </div>
                        <div class="mt-3 text-center">
                          <a href="/assets/osx-03.png"><img src="/assets/osx-03.png"
                                alt="Click Add to Home Screen"></a>
                        </div>
                      </li>
                      <li>
                        Then select "Add" in the upper right hand corner.
                        <div class="mt-2 text-center">
                          <a href="/assets/osx-04.png"><img src="/assets/osx-04.png"
                              alt="Then select Add in the upper right hand corner"></a>
                        </div>
                      </li>
                  </ol>
              </div>
              <div v-else-if="os === 'Android'">
                  <h2 id="android">Android</h2>

                  <ol class="text-left ml-0">
                    <li class="mb-3">
                        When the app runs for the first time, there is an option to install a app icon to your phone
                        desktop.
                        Upon initially running the app, an “Install App to Desktop” dialog displays.
                        Select the Install button to start the process to place the icon onto the desktop.

                      <div class="mt-2 text-center">
                        <a href="/assets/android-01.png"><img src="/assets/android-01.png"
                                                          alt="Install App to Desktop"></a>
                      </div>
                    </li>

                    <li class="mb-3">
                      Once the Install app dialog appears, select the Install button to complete the install
                        process.
                      <div class="mt-2 text-center">
                        <a href="/assets/android-02.png"><img src="/assets/android-02.png"
                                                              alt="Install dialog"></a>
                      </div>
                    </li>

                    <li>
                      The image on the left displays the Trash App icon installed on the mobile phone desktop.
                      <div class="mt-2 text-center">
                        <a href="/assets/android-03.png"><img src="/assets/android-03.png"
                                                              alt="Icon on desktop"></a>
                      </div>
                    </li>
                  </ol>
              </div>
              <div v-else>
                  <h2>Other</h2>
                  <p>Sorry, we do not have instructions on installing the app for this device.</p>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
export default {
    name: "InstallationInstructions",
    components: { PageHeader },
    data() {
        return {
            os: null
        }
    },
    mounted() {
        this.os = this.getOS();
    },
    methods: {
        setOS(os) {
          this.os = os;
        },
        getOS() {
            var userAgent = window.navigator.userAgent,
                platform = window.navigator.platform,
                macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'Mac OS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'Linux';
            }

            return os;
        }
    }
}
</script>
