<template>
    <div class="page-success">
      <page-header h1='Success'></page-header>

      <div class="container">
        <div class="row">
          <div class="col-12 mx-auto">
            <p>Your dispatcher and the Facility Manager have been notified to resolve this issue.</p>
          </div>
        </div>
        <div class="row mt-2">
            <div class="col-12 mx-auto">
                <button class="btn btn-primary center" @click="done">Done</button>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
  import PageHeader from "@/components/PageHeader";
  export default {
    name: "Success",
    components: { PageHeader},
    methods: {
      done() {
        this.$store.commit('SET_ISSUE', null)
        this.$router.push('/action')
      }
    }
  }
</script>
