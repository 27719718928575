<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="page-add-issue">
        <div class="add-issue form" v-if="!error">
          <page-header :h1="'Issue: ' + issue" back='/'></page-header>

            <div class="container">
              <form @submit.prevent="handleSubmit">
                <div class="row mb-3">
                    <div class="col-12">
                        <label>Select Stop</label>
                        <a href="#" @click.prevent="handleRefresh" title="Refresh Locations">
                          <img src="/img/icons/refresh.svg" alt="Refresh icon" width="20" id="select-location-refresh"
                             :class="{loading: loading}">
                        </a>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <ul>
                            <li class="form-check" v-for="location in locations" :key="location.id">
                                <input class="form-check-input" type="radio" :value="location.id"
                                       v-model="selectedLocation" :id="'stop_' + location.id">
                                <label :for="'stop_' + location.id" class="font-weight-normal form-check-label w-100 pl-2 text-left">
                                    {{location.address}}
                                </label>
                            </li>
                            <p v-if="!loading && locations.length === 0" class="text-danger mr-5">No Locations Found</p>
                        </ul>

                    </div>
                </div>

                <photo-uploader
                        ref="photoUploader"
                        :end-point="photoUrl"
                        :multipart="true"
                        :multiple="true"
                        @uploaded="doneUploading"
                        :headers="{
                                  'Authorization': 'Bearer ' + this.$store.state.token
                        }"

                        @change="allowSubmit = ($refs.photoUploader.files.length > 0)"
                        :maxUploads="2"
                        accept="image/png, image/jpeg"
                >
                    <template v-slot:header><span></span></template>
                    <template v-slot:browse-btn>
                        <div class="btn btn-sm btn-primary upload-photos-button">
                            <img src="/img/icons/white-camera.png" alt="Upload photos icon" width="30">
                            <span>Upload Photos</span>
                        </div>
                    </template>
                </photo-uploader>

                <div class="row mt-1">
                    <div class="col-12">
                        <label for="notes" class="w-100 mx-auto">Enter issue notes</label>
                        <textarea class="form-control" v-model="notes" id="notes"></textarea>
                    </div>
                </div>

                <div class="row mt-4 mb-4">
                    <button type="submit" class="w-50 mx-auto btn btn-primary loader"
                            :disabled="locations.length === 0 || (!allowSubmit && !notes)"
                    >
                        <div class="spinner-border spinner-border-sm" v-if="saving"></div>
                      <span>Submit</span>
                    </button>
                </div>

                <div class="row pb-5 center">
<!--                    <a href="#" class="w-50 mx-auto" @click.prevent="done">Back to Report Pickup Issue</a>-->
                </div>
              </form>
            </div>

        </div>
        <div class="add-issue success" v-if="error">
          <page-header h1='Success'></page-header>

          <div class="container">
            <div class="row mt-5 pb-5">
                <div class="col-9 center">
                    <button class="btn btn-primary center" @click="error = null">Retry</button>
                </div>
                <div class="col-9 center">
                    <button class="btn btn-primary center" @click="done">Done</button>
                </div>
            </div>
          </div>
        </div>

    </div>
</template>

<script>
  import PhotoUploader from "../components/PhotoUploader";
  import PageHeader from "@/components/PageHeader";

  export default {
    name: 'AddIssue',
    components: {PhotoUploader, PageHeader},
    data() {
      return {
        allowSubmit: false,
        images: [],
        notes: null,
        location: null,
        issue: this.$store.state.issue,
        locations: [],
        selectedLocation: null,
        error: null,
        loading: false,
        work_order_id: null,
        saving: false,
        base_url: process.env.VUE_APP_API_URL,
        photoUrl: null,
      }
    },
    created() {
      this.getPosition()
    },
    mounted() {
      if (!this.issue) {
        this.done()
      }
    },
    methods: {
      handleRefresh() {
        this.locations = []
        this.getPosition()
      },
      handleSubmit() {
        this.saving = true

        let data = new FormData()
        data.append('issue_type', this.issue)
        data.append('location_id', this.selectedLocation)
        data.append('note', this.notes)
        window.axios.post(this.base_url + 'driver-issue',
          data,
          {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.token,
              'Content-Type': 'multipart/form-data'
            },

          }
        )
        .then(res => {
          this.work_order_id = res.data.work_order_id
          this.photoUrl = this.base_url + 'driver-image/' + this.work_order_id;


          this.$nextTick(() => this.uploadImages())

        })
        .catch(e => {
          this.error = e
          this.saving = false
        })
      },

      getPosition() {
        this.loading = true

        if (navigator.permissions && navigator.permissions.query) {
          //try permissions APIs first
          navigator.permissions.query({name: 'geolocation'}).then((result) => {
            // Will return ['granted', 'prompt', 'denied']
            const permission = result.state;
            if (permission === 'granted' || permission === 'prompt') {
              this.requestGeoLocation();
            } else {
              this.$router.push('/location-error')
              this.loading = false
            }
          });
        } else if (navigator.geolocation) {
          //then Navigation APIs
          this.requestGeoLocation();
        } else {
          this.$router.push('/location-error')
          this.loading = false
        }

      },

      requestGeoLocation() {

        navigator.geolocation.getCurrentPosition(position => {
          this.showPosition(position)
          this.getLocations()
        }, () => {
          this.$router.push('/location-error')
          this.loading = false
        });
      },

      getLocations() {
        let coords = `${this.location.latitude}/${this.location.longitude}`
        window.axios.get(process.env.VUE_APP_API_URL + 'whats-close-to-me/' + coords,
          {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.token
            }
          }
        )
        .then(res => {
          this.locations = res.data.locations
          this.loading = false
          this.selectedLocation = this.locations[0].id
        })
        .catch(e => {
          this.loading = false
          console.error(e)
        })

      },
      showPosition(position) {
        this.location = {
          longitude: position.coords.longitude,
          latitude: position.coords.latitude
        }
      },
      done() {
        this.$store.commit('SET_ISSUE', null)
        this.$router.push('/action')
      },
      uploadImages() {
        this.$refs.photoUploader.upload()
      },
      doneUploading() {
        window.axios.get(
          process.env.VUE_APP_API_URL + 'driver-issue/' + this.work_order_id + '/complete',
          {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.token
            }
          }
        )
        .then(() => {
          this.saving = false
          this.$router.push('/success')
        })
        .catch(e => {
          this.loading = false
          console.error(e)
        })

      }
    }
  }
</script>
