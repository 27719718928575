<template>
  <div class="container page-header">
    <div class="row mb-4 mt-3">
      <div class="col-12 text-center">
        <h1>{{ h1 }}</h1>
        <div v-if="back" class="mt-2">
          <a href="#" @click.prevent="handleLink('/')">
            <img src="/img/icons/arrow_left_cropped.svg" class="mr-2" width="15" alt="">
            <span style="position:relative;top:1px;">Back</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageHeader',
  props: [ 'h1', 'back' ],
  methods: {
    handleLink(route) {
      this.$store.commit('SET_SHOW_MENU', false)
      this.$router.push(route).catch(() => {
      })
    },
  }

}
</script>
