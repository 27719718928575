<template>
  <div class="page-location-error">
    <page-header h1='A Problem Has Occurred' back='/'></page-header>

      <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="text-danger"><strong>This application requires Location services to be enabled.</strong></p>

                <h2 class="mb-2">Select Device Type:</h2>
                <ul class="mb-4 ml-0 pl-0" style="list-style-type:none;">
                  <li>
                    <span v-if="os === 'iOS'">iOS</span>
                    <a v-else href="#" @click.prevent="setOS('iOS')">iOS</a>
                  </li>
                  <li>
                    <span v-if="os === 'Android'">Android</span>
                    <a v-else href="#" @click.prevent="setOS('Android')">Android</a></li>
                  <li>
                    <span v-if="os !== 'iOS' && os !== 'Android'">Other</span>
                    <a v-else href="#" @click.prevent="setOS(null)">Other</a>
                  </li>
                </ul>

                  <!--<div v-if="os === 'Mac OS'">
                      <h2>Apple Laptop</h2>
                      On Apple Laptop
                  </div>-->
                  <div v-if="os === 'iOS'">
                      <h2>Apple iOS</h2>
                      <p>To continue, allow access to your location by following these steps.</p>
                      <ol class="text-left ml-0">
                          <li class="mb-3">
                            Press the "Aa" in the left-hand corner of the browser.
                            <div class="mt-2 text-center">
                              <a href="/assets/location-services/ios/ios-AA.png"><img src="/assets/location-services/ios/ios-AA.png"
                                  alt="Top of browser screen with AA in upper left hand corner."></a>
                            </div>
                          </li>
                          <li class="mb-3">
                            Now select Web Site Settings.
                            <div class="mt-2 text-center">
                              <a href="/assets/location-services/ios/ios-web-settings.png"><img src="/assets/location-services/ios/ios-web-settings.png"
                                  alt="Setting screen"></a>
                            </div>
                          </li>
                          <li class="mb-3">
                            Select Location and click allow
                            <div class="mt-2 text-center">
                              <a href="/assets/location-services/ios/ios-location-allow.png"><img src="/assets/location-services/ios/ios-location-allow.png"
                                  alt="Screen were you can set web site to access/allow location"></a>
                            </div>
                          </li>

                          <li>
                            Now go to the Hamburger menu in the upper right-hand corner, and select refresh and you
                              should be good to go.
                            <div class="mt-2 text-center">
                              <a href="/assets/location-services/ios/ios-refresh.png"><img src="/assets/location-services/ios/ios-refresh.png"
                                alt="Screen showing menu in upper left hand corner and option to refresh"></a>
                            </div>
                          </li>
                      </ol>
                  </div>
                  <div v-else-if="os === 'Android'">
                      <h2>Android</h2>
                      <p>To continue, allow access to your location by following these steps.</p>
                      <ol class="text-left ml-0">
                          <li class="mb-3">
                            Go to Settings and select Location.
                            <div class="mt-2 text-center">
                              <a href="/assets/location-services/android/settings-and-location.png"><img src="/assets/location-services/android/settings-and-location.png"
                                alt="Go to Settings and select Location."></a>
                            </div>
                          </li>
                          <li>
                            Now choose the Location toggle to turn on Location Services.
                            <div class="mt-2 text-center">
                              <a href="/assets/location-services/android/location-toggle.png"><img src="/assets/location-services/android/location-toggle.png"
                                 alt="Now choose the Location toggle to turn on Location Services."></a>
                            </div>
                          </li>
                      </ol>
                  </div>
                  <div v-else>
                    <h2>Other</h2>
                      <p>To continue, allow access to your location.</p>
                  </div>

            </div>
        </div>
      </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
export default {
    name: "LocationError",
    components: { PageHeader },
    data() {
        return {
            os: null
        }
    },
    mounted() {
        this.os = this.getOS();
    },
    methods: {
        setOS(os) {
          this.os = os;
        },
        getOS() {
            var userAgent = window.navigator.userAgent,
                platform = window.navigator.platform,
                macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'Mac OS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'Linux';
            }

            return os;
        }


    }
}
</script>
